import { NavLink, type NavLinkProps } from '@remix-run/react';
import type { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { CircularProgress } from './circular-progress';

type Props = PropsWithChildren<{
  to: string;
  className?: string;
  iconClassName?: string;
}> &
  NavLinkProps;

export function PrimaryNavLinkButton({
  to,
  children,
  className,
  target,
  prefetch,
  iconClassName,
  'aria-label': ariaLabel,
}: Props) {
  return (
    <NavLink
      to={to}
      end
      aria-label={ariaLabel}
      target={target}
      prefetch={prefetch}
      className={twMerge(
        'flex h-[44px] w-full items-center justify-center gap-[5px] rounded-[4px] bg-primary text-[14px] text-white no-underline hover:text-white hover:no-underline hover:opacity-70 disabled:opacity-70',
        `${className}`,
      )}
    >
      {({ isPending }) => (
        <>
          {children}
          {isPending && <CircularProgress className={twMerge('h-[12px] w-[12px] text-white', iconClassName)} />}
        </>
      )}
    </NavLink>
  );
}
